.a2hs-overlay {
    display: none;
    flex-direction: column;
    align-items:center;
    justify-content: flex-end;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.a2hs-overlay.visible {
    display: flex;
}

.a2hs-dialog {
    width: 100%;
    background-color: #59596F;
    color: #fff;
    padding:16px 16px;
    border-radius: 12px 12px 0px 0px;
    box-shadow: 0px -5px 15px -9px #000000;
}

.a2hs-dialog .icon {
   width: 60px;
   height: auto;
   margin-left: -8px;
}

.a2hs-dialog .title {
    font-size: 14px;
    font-weight: bold;
}

.a2hs-dialog p {
    font-size: 12px;
    font-weight: 300;
}

.a2hs-dialog .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.a2hs-dialog .button-group.ios {
    justify-content: flex-end;
}

.a2hs-dialog button {
    font-size: 14px;
    font-weight: 300;
    background-color: #fff;
    color: #444;
    padding: 8px;
    border-radius: 6px;
    appearance: none;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 120px;
    box-shadow: 0px -5px 15px -9px #000000;
}

.a2hs-dialog button + button {
    margin-left: 32px;
}