:root {
    --pze-scale: 1;
    --pze-pos-x: 0px;
    --pze-pos-y: 0px;
}

.pze-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    touch-action: none;
}

.pze-viewport {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    /* overflow: visible!important; */
    /* counter-reset: item; */
    scroll-behavior: smooth;
    scroll-snap-type: none;
    scroll-snap-align: center;
}

.pze-viewport > img {
    object-fit: cover;
    max-width: 100%;
    height: auto;
    backface-visibility: hidden;
    transform: translateZ(0);
    opacity: 0.3;
    filter: none;
}

.pze-viewport > img.loaded.single {
    transition: transform 0.2s ease-out;
    -webkit-transition: -webkit-transform 0.2s ease-out;
}

.pze-viewport > img.loaded {
    opacity: 1;
    filter: none;
    -webkit-transition: opacity 0.3s ease-in, filter 0.3s ease-out;
    transition: opacity 0.3s  ease-in, filter 0.3s  ease-out;
}

/* .pze-viewport > *{
    transform: scale(var(--pze-scale)) translate(var(--pze-pos-x), var(--pze-pos-y));
} */
